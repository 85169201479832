export function setS3galeria(state, S3galeria) {
  state.S3galeria = S3galeria;
}
export function setS3carpetas(state, S3carpetas) {
  state.S3carpetas = S3carpetas;
}






