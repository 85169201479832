export function getDeportes(state) {
  return state.deportes;
}










