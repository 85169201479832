export function setMensajes(state, mensajes) {
  state.mensajes = mensajes;
}
export function setMensajesTipo(state, mensajesTipo) {
  state.mensajesTipo = mensajesTipo;
}






