export function getPromociones(state) {
  return state.promociones;
}







