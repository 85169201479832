import baseApi from "@/api/baseApi";

export const getAyudasAsync = async ({ commit }, obj) => {
  const { data } = await baseApi.get("/api/v1/getAyuda", {
    params: {
      perPage: obj.perPage,
      pagina_id: obj.pagina_id,
    },
  });
  commit("setAyudas", data.data);
  return data;
};

export const getPaginaAsync = async ({ commit }) => {
  const { data } = await baseApi.get("/api/v1/getPagina");
  commit("setPaginas", data.data);
  return data;
};

export const createAyudaAsync = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/createAyuda", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};

export const editAyudaAsync = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/editAyuda", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};
export const deleteAyudaAsync = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/deleteAyuda", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};
