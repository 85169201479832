import baseApi from "@/api/baseApi";

export const getDeporte = async ({commit}) => {
  try {
    const { data } = await baseApi.get("/api/v1/getDeporte");   
    commit("setDeportes",data.data)
    return data;
  } catch (error) {
    console.log("ERRor;:;",error) 
  }
};

export const deleteMensaje = async (contex,obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/deleteMensaje",obj);    

    return data;
  } catch (error) {
    console.log("ERRor;:;",error) 
  }
};

export const createDeporte = async (contex,obj) => {
  try {    
    const { data } = await baseApi.post("/api/v1/createDeporte",obj);         
    return data;
  } catch (error) {
    console.log("ERRor;:;",error) 
  }
};

export const editDeporte = async (contex,obj) => {
  try {    
    const { data } = await baseApi.post("/api/v1/editDeporte",obj);         
    return data;
  } catch (error) {
    console.log("ERRor;:;",error) 
  }
};
export const deleteDeporte = async (contex,obj) => {
  try {    
    const { data } = await baseApi.post("/api/v1/deleteDeporte",obj);         
    return data;
  } catch (error) {
    console.log("ERRor;:;",error) 
  }
};



