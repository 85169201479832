<template>
  <form @submit.prevent="submitResePassword" class="box-login">
    <h4 class="text-center py-3 text-light">SYSTEM4SELLA</h4>
    <div class="container">
      <div class="row justify-content-center mt-3">
        <div class="col-md-6">
          <label class="text-light" for="">Password</label>
          <input
            v-model="objLogin.password"
            class="form-control"
            type="password"
          />
          <small class="form-text txt-red" v-if="v$.password.$error">
            *Debe colocar una contraseña.
          </small>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-md-6">
          <label class="text-light" for="">Repetir Password</label>
          <input
            v-model="objLogin.password_confirmation"
            v-on:keyup="validarPassword"
            type="password"
            :class="{
              'form-control': true,
              valid: !v$.$error && v$.$dirty,
              error: v$.$error,
            }"
          />

          <small class="form-text txt-red" v-if="!validarPassword()">
            *Los password deben ser iguales.
          </small>
        </div>
      </div>

      <!-- <div class="text-center mt-3">
          <a v-on:click="backLogin()" href="/">Login</a>
        </div> -->

      <!-- <div class="row justify-content-center mt-3"> -->
      <div class="text-center mt-3">
        <button type="submit" class="myButtonAceptar">
          Restablecer password
        </button>
      </div>
      <!-- </div> -->
    </div>
  </form>
</template>

<script>
import { inject } from "vue-demi";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useStore } from "vuex";
import Swal from "sweetalert2";

import useAuth from "../composable/useAuth";
export default {
  setup() {
    const objLogin = inject("objLogin");
    const store = useStore();
    const { resetPassword } = useAuth();
    const rules = {
      password: {
        required,
      },
      password_confirmation: {
        required,
      },
    };

    const v$ = useVuelidate(rules, objLogin);

    const validarPassword = () => {
      if (objLogin.password == "") {
        return true;
      }
      if (
        objLogin.password == objLogin.password_confirmation &&
        objLogin.password != "" &&
        objLogin.password_confirmation.length > 3
      ) {
        return true;
      }
      return false;
    };

    const submitResePassword = async () => {
      store.commit("showLoader");      
      let data = await resetPassword(objLogin);
      if (data.code == 200) {
        localStorage.setItem("page", "login");
        window.location.href = "/";
        store.commit("hideLoader");
        return Swal.fire("Excelente!", data.message, "success");
      } else {
        store.commit("hideLoader");
        return Swal.fire("Error!", data.message, "error");
      }
    };
    const backLogin = () => {
      localStorage.setItem("page", "login");
    };

    return { objLogin, validarPassword, v$, submitResePassword, backLogin };
  },
};
</script>

<style scoped>
.box-login {
  height: 330px;
  width: 500px;
  margin: auto;
  margin-top: 200px;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  background: #212529;
}

@media (max-width: 1000px) {
  .box-login {
    margin-top: 20px;
    width: 300px;
  }
}
</style>