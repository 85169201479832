export function setPronostico(state, pronostico) {
  state.pronostico = pronostico;
}
export function setEvento(state, evento) {
  state.evento = evento;
}
export function setCompetidores(state, competidores) {
  state.competidores = competidores;
}
export function setDeporte(state, deporte) {
  state.deporte = deporte;
}







