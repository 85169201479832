import baseApi from "@/api/baseApi";

export const getSlider = async ({ commit }, obj) => {
  const { data } = await baseApi.get("/api/v1/getSlider", {
    params: {
      perPage: obj.perPage,
    },
  });
  commit("setSlider", data.data);
  return data;
};

export const createSlider = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/createSlider", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};

export const editSlider = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/editSlider", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};
export const deleteSlider = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/deleteSlider", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};
