import baseApi from "@/api/baseApi";

export const getParametros = async ({ commit }) => {
  const { data } = await baseApi.get("/api/v1/getParametros");
  commit("setParametros", data.data);
  return data;
};

export const createParametros = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/createParametros", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};

export const editParametros = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/editParametros", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};
export const deleteParametros = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/deleteParametros", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};
