import { createStore } from "vuex";
import jwt from "jsonwebtoken";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
// import $ from 'jquery';
import Swal from "sweetalert2";
import baseApi from "@/api/baseApi";
import auth from "../modules/auth/store";
import ayudas from "../modules/ayudas/store";
import banco from "../modules/banco/store";
import notas from "../modules/notas/store";
import slider from "../modules/slider/store";
import usuarios from "../modules/usuarios/store";
import mensaje from "../modules/mensaje/store";
import galeria from "../modules/galeria/store";
import promociones from "../modules/promociones/store";
import parametros from "../modules/parametros/store";
import pronostico from "../modules/pronostico/store";
import deportes from "../modules/deportes/store";

export default createStore({
  plugins: [createPersistedState()],
  state: {
    loader: false,            
    visualWidth: window.visualViewport
      ? window.visualViewport.width
      : window.innerWidth,
    visualHeight: window.visualViewport.width,
    dashboard: null,
    contacto: null,
    bitacora: null,  
  },
  getters: {  
    getVisualWidth(state) {
      return state.visualWidth;
    },
    getVisualHeight(state) {
      return state.visualHeight;
    },
    getBitacora(state) {
      return state.bitacora;
    },
  },
  mutations: {
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    },  
    setContacto(state, payload) {
      state.contacto = payload;
    }, 
    setDashboard(state, payload) {
      state.dashboard = payload;
    },
    setBitacora(state, payload) {
      state.bitacora = payload;
    },
    setVisualWidth(state, width) {
      state.visualWidth = width;
    },
    setVisualHeight(state, height) {
      state.visualHeight = height;
    },   
  },
  actions: {
    async refresfToken({ commit }) {
      if (localStorage && localStorage.getItem("sp_tk")) {
        const tkSerial = localStorage.getItem("sp_tk");

        jwt.verify(
          tkSerial,
          process.env.VUE_APP_API_SECRET,
          function (err, decoded) {
            if (!err && decoded.tk) {
              /* Renovacion de TOKEN */
              const token = decoded.tk;

              let tokenSerealized = jwt.sign(
                {
                  tk: token,
                  creation: new Date(),
                  online: true,
                },
                process.env.VUE_APP_API_SECRET,
                { expiresIn: process.env.VUE_APP_SESSION_EXPIRATION }
              );

              localStorage.setItem("sp_tk", tokenSerealized);
              commit("setToken", { access_token: decoded.tk });
            } else {
              localStorage.removeItem("sp_tk");
            }
          }
        );
      }
    },
    async checkSession({ commit, dispatch }) {
      if (localStorage && localStorage.getItem("sp_tk")) {
        const tkSerial = localStorage.getItem("sp_tk");

        jwt.verify(
          tkSerial,
          process.env.VUE_APP_API_SECRET,
          async function (err, decoded) {
            if (!err && decoded.tk) {
              commit("setToken", { access_token: decoded.tk });
              await dispatch("refresfToken");
              // await dispatch("meAsync");
            } else {
              localStorage.removeItem("sp_tk");
            }
          }
        );
      }
    },
    async meAsync({ commit, state }) {
      commit("showLoader");
      if (!state.token) {
        commit("hideLoader");
        return false;
      }

      let config = {
        headers: { Authorization: `Bearer ${state.token.access_token}` },
      };
      let obj = "";
      await axios
        .post(process.env.VUE_APP_URL_API + "/api/auth/v1/me", obj, config)
        .then((response) => {
          if (response.data.code == 200) {
            commit("hideLoader");
            commit("setUser", response.data.data.user);
          }
          if (response.data.code == 204) {
            commit("hideLoader");
          }
          if (response.data.code == 400) {
            commit("hideLoader");
          }
        })
        .catch((err) => {
          commit("hideLoader");
          console.log("error", err);
        });
    },

    async isValidToken() {
      return jwt.verify(
        localStorage.getItem("sp_tk"),
        process.env.VUE_APP_API_SECRET,
        async function (err, decoded) {
          return !err && decoded.tk ? true : false;
        }
      );
    },
    clearAndRedirect({ commit }) {
      commit("setToken", null);
      commit("setUser", null);
      // removiendo token expirado
      localStorage.removeItem("sp_tk");
    },
    async createPost({ commit, dispatch, state }, obj) {
      commit("showLoader");
      if (!state.auth.token) {
        commit("hideLoader");
        return false;
      }

      let config = {
        headers: { Authorization: `Bearer ${state.auth.token}` },
      };
      await axios
        .post(process.env.VUE_APP_URL_API + obj.url, obj.data, config)
        .then((response) => {
          if (response.data.code == 200) {
            commit("hideLoader");
            if (obj.nomsj) {
              return true;
            }
            return Swal.fire("Excelente!", response.data.message, "success");
          }
          if (response.data.code == 204) {
            commit("hideLoader");
            if (obj.nomsj) {
              return true;
            }
            return Swal.fire("Excelente!", response.data.message, "success");
          }
          if (response.data.code == 400) {
            commit("hideLoader");
            return Swal.fire("Error!", response.data.message, "error");
          }
          if (response.data.code == 500) {
            commit("hideLoader");
            console.log("Error::::", response.data.exception);
            return Swal.fire("Error!", response.data.message, "error");
          }
        })
        .catch((err) => {
          commit("hideLoader");
          dispatch("clearAndRedirect");
          console.log("error", err);
        });
    },
    async getBitacora({ commit }, obj) {
      const { data } = await baseApi.get("/api/v1/getBitacora", {
        params: {
          perPage: obj.perPage,
          accion: obj.accion,
        },
      });
      commit("setBitacora", data.data);
      return data;
    },
    async getData({ commit, dispatch, state }, obj) {
      commit("showLoader");
      if (!state.auth.token) {
        commit("hideLoader");
        return false;
      }
      let config = {
        headers: { Authorization: `Bearer ${state.auth.token}` },
      };

      await axios
        .get(process.env.VUE_APP_URL_API + obj.url, config)
        .then((response) => {
          commit(`${obj.set}`, response.data.data);
        })
        .catch((err) => {
          dispatch("clearAndRedirect");
          console.log("err:::", err);
        });
      commit("hideLoader");
    },  
    async resetPasswordAsync({ commit }, obj) {
      commit("showLoader");
      await axios
        .post(process.env.VUE_APP_URL_API + "/api/v1/resetPassword", obj)
        .then((response) => {
          if (response.data.code == 200) {
            commit("hideLoader");
            return Swal.fire("Excelente!", response.data.message, "success");
          }
          if (response.data.code == 400) {
            commit("hideLoader");
            return Swal.fire("Error!", response.data.message, "error");
          }
        })
        .catch((err) => {
          console.log("Error:::", err);
        });
      commit("hideLoader");
    },
  },
  modules: {
    auth,
    ayudas,
    banco,
    notas,
    slider,
    usuarios,
    mensaje,
    galeria,
    promociones,
    pronostico,
    parametros,
    deportes
  },
});
