export function setPromociones(state, promociones) {
  state.promociones = promociones;
}







