<template>
  <div id="loader" class="container-loader" v-if="cargando">
    <div class="loader">



        <div class="spinner-border" role="status">
          <span class="visually-hidden" ></span>
        </div>
   
      
    </div>
  </div>
</template>
<script>
export default {
  name: "Loader",
  props: {
    cargando: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style scoped="scoped">
div#loader.container-loader {
  width: 100%;
  z-index: 20000;
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color:red; */
  height: 100vh;
  text-align: center;
  align-content: center;
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction:column;  
  top: 0;

}
div#loader > div.loader {
  align-items: center;
  justify-content: center;
  border-width: 5px;
}

.spinner-border{
  color: #fff !important;
  width: 5rem !important; 
  height: 5rem !important;
}

</style>
