import { computed } from "vue";
import { useStore } from "vuex";

const useAuth = () => {
  const store = useStore();

  const createUser = async (user) => {
    const resp = await store.dispatch("auth/createUser", user);
    return resp;
  };

  const loginUser = async (user) => {
    const resp = await store.dispatch("auth/signInUser", user);
    return resp;
  };

  const logout = async () => {
    const resp = await store.dispatch("auth/logout");
    return resp;
  };
  const sendPasswordResetLink = async (user) => {    
    const resp = await store.dispatch("auth/sendPasswordResetLinkAsync", user);
    return resp;
  };
  const resetPassword = async (user) => {    
    const resp = await store.dispatch("auth/resetPasswordAsync", user);
    return resp;
  };
  const checkSession = async () => {    
    const resp = await store.dispatch("auth/checkSession");
    return resp;
  };



  return {
    sendPasswordResetLink,
    createUser,
    loginUser,
    logout,
    resetPassword,
    checkSession,
    isLoged: computed(() => {
      return store.getters["auth/getUser"] &&
        store.getters["auth/getUser"] != null &&
        store.getters["auth/getUser"] != undefined
        ? true
        : false;
    }),
    user: computed(() => store.getters["auth/getUser"]),
  };
};

export default useAuth;
