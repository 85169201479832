import baseApi from "@/api/baseApi";

export const getBanco = async ({ commit }) => {
  const { data } = await baseApi.get("/api/v1/getCuentaBanco");
  commit("setCuentaBanco", data.data);
  return data;
};

export const createCuentaBanco = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/createCuentaBanco", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};

export const editCuentaBanco = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/editCuentaBanco", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};
export const deleteCuentaBanco = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/deleteCuentaBanco", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};
