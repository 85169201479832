import { createRouter,createWebHashHistory } from 'vue-router'
import store from '../store/index.js'
const routes = [
  {
    path: '/',
    name: 'Home',    
    component: () => import("../views/Home.vue")
  },
  {
    path: '/user',
    name: 'User',        
    component: () => import("../modules/usuarios/view/User.vue"),
    meta: { rol: 'Administrador' } 
  },
  {
    path: '/ayudas',
    name: 'Ayudas',
    component: () => import("../modules/ayudas/view/Ayudas.vue")   
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: () => import("../views/Perfil.vue"),    
  },
  {
    path: '/slider',
    name: 'Slider',
    component: () => import("../modules/slider/view/Slider.vue")      
  },
  {
    path: '/cuenta-banco',
    name: 'CuentaBanco',
    component: () => import("../modules/banco/view/CuentaBanco.vue")      
  },
  {
    path: '/promociones',
    name: 'Promociones',    
    component: () => import("../modules/promociones/view/Promociones.vue"),         
  },
  {
    path: '/pronosticos',
    name: 'Pronosticos',    
    component: () => import("../modules/pronostico/view/Pronostico.vue"),         
  },
  {
    path: '/pronosticos-editar/:id',
    name: 'PronosticosEditar',    
    component: () => import("../modules/pronostico/view/PronosticoEditar.vue"),         
  },
  {
    path: '/eventos-editar/:id',
    name: 'EventosEditar',    
    component: () => import("../modules/pronostico/view/EventoEdit.vue"),         
  },
  {
    path: '/parametros',
    name: 'Parametros',
    component: () => import("../modules/parametros/view/Parametros.vue"),             
    // component: () => import("../views/Parametros.vue"),             
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: () => import("../views/Contacto.vue"),                 
  },
  {
    path: '/notas',
    name: 'Notas',
    component: () => import("../modules/notas/view/Notas.vue")      
  },         
  {
    path: '/mensaje',
    name: 'Mensaje',    
    component: () => import("../modules/mensaje/view/Mensaje.vue"),       
  },
  {
    path: '/galeria',
    name: 'Galeria',    
    // component: () => import("../components/S3Galeria.vue"),       
    component: () => import("../modules/galeria/view/S3Galeria"),       
  },
  {
    path: '/bitacora',
    name: 'Bitacora',
    component: () => import("../views/Bitacora.vue"),    
    meta: { rol: 'Administrador' } 
  },
  {
    path: '/deportes',
    name: 'Deportes',
    component: () => import("../modules/deportes/view/Deportes.vue"),        
  },
]

const router = createRouter({  
  history: createWebHashHistory(),  
  routes
})

router.beforeEach((to, from,next) => {      
  if(!store.state.auth.user){
    next()
  }else{
    if(to.meta.rol == store.state.auth.user.role.nombre){    
      next()
    }else if(to.path == '/user'|| to.path =='/bitacora'){    
      next('/')    
    }else{
      next()
    }
  }
})



export default router
