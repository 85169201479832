import baseApi from "@/api/baseApi";

export const getMensaje = async ({ commit }, obj) => {
  const { data } = await baseApi.get("/api/v1/getMensaje", {
    params: {
      perPage: obj.perPage,
      tipo_mensaje_id:obj.tipo_mensaje_id
    },
  });
  commit("setMensajes", data.data);
  return data;
};
export const getMensajeTipo = async ({ commit }) => {
  const { data } = await baseApi.get("/api/v1/getMensajeTipo");    
  commit("setMensajesTipo", data.data);
  return data;
};

export const createMensaje = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/createMensaje", obj);
    return data;
    
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};

export const editMensaje = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/editMensaje", obj);
    return data;
    
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};
export const deleteMensaje = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/deleteMensaje", obj);
    return data;
    
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};
