import axios from "axios";

const baseApi = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
});

baseApi.interceptors.request.use(
  config => {
      const token = localStorage.getItem("token");      
      if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
      }      
      return config;
  },
  error => {
      Promise.reject(error)
  });
export default baseApi;
