<template>
  <form @submit.prevent="login" class="box-login" v-if="!reset">
    <h4 class="text-center py-3 text-light">SYSTEM4SELLA</h4>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <label class="text-light" for="">Email</label>
          <input v-model="objLogin.email" class="form-control" type="email" />
          <small class="form-text txt-red" v-if="v$.email.$error">
            *Debe colocar un email.
          </small>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-md-6">
          <label class="text-light" for="">Password</label>
          <input
            v-model="objLogin.password"
            class="form-control"
            type="password"
          />
          <small class="form-text txt-red" v-if="v$.password.$error">
            *Debe colocar una contraseña.
          </small>
        </div>
      </div>

      <div class="row justify-content-center mt-3">
        <div class="col-md-3">
          <button type="submit" class="myButtonAceptar">Ingresar</button>
        </div>
      </div>

      <div class="row justify-content-end mt-3">
        <div class="col-md-6">
          <a v-on:click="resetPassword()" href="#">¿Olvide mi password?</a>
        </div>
      </div>
    </div>
  </form>

  <div class="box-reset container" v-if="reset">
    <h4 class="text-center py-3"></h4>
    <div class="row justify-content-center">
      <div class="col-md-8">
        <label class="text-light">Email</label>
        <input v-model="objLogin.email" class="form-control" type="email" />
        <small class="form-text txt-red" v-if="validarEmail">
          *Debe colocar un email.
        </small>
      </div>
    </div>

    <div class="text-center mt-3">
      <button @click="enviarMail()" type="button" class="myButtonAceptar">
        Enviar email
      </button>
    </div>

    <div class="row justify-content-center mt-3">
      <div class="col-md-2">
        <a v-on:click="resetPassword()" href="#">Login</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { inject, provide } from "@vue/runtime-core";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { useRouter } from "vue-router";

import useAuth from "../composable/useAuth";
import Swal from "sweetalert2";

export default {
  name: "Login",
  setup() {
    const reset = ref(false);
    const validarEmail = ref(false);
    const store = useStore();
    const router = useRouter();
    const objLogin = inject("objLogin");

    const { loginUser, sendPasswordResetLink } = useAuth();

    const resetPassword = () => {
      reset.value = !reset.value;
    };

    const rules = {
      email: {
        required,
      },
      password: {
        required,
      },
    };

    const v$ = useVuelidate(rules, objLogin);
    const login = async () => {
      v$.value.$touch();
      if (v$.value.$invalid) {
        return false;
      }
      store.commit("showLoader");
      let data = await loginUser(objLogin);
      if (data.code != 200) {
        Swal.fire("Error!", data.message, "error");
      }
      router.push("/");
      store.commit("hideLoader");
    };

    const enviarMail = async () => {
      store.commit("showLoader");
      if (objLogin.email) {
        validarEmail.value = false;
        let data = await sendPasswordResetLink(objLogin);
        if (data.code == 200) {
          Swal.fire("Excelente!", data.message, "success");
        } else {
          Swal.fire("Error!", data.message, "error");
        }
        resetPassword();
      } else {
        validarEmail.value = true;
      }
      store.commit("hideLoader");
    };

    provide("reset", reset, enviarMail);
    return {
      reset,
      resetPassword,
      objLogin,
      login,
      v$,
      enviarMail,
      validarEmail,
    };
  },
};
</script>

<style scoped>
.box-login {
  height: 330px;
  width: 500px;
  margin: auto;
  margin-top: 200px;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  background: #212529;
}

@media (max-width: 1000px) {
  .box-login {
    margin-top: 20px;
    width: 300px;
  }
}

.box-reset {
  height: 220px;
  width: 400px;
  margin: auto;
  margin-top: 200px;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  background: #212529;
}

@media (max-width: 1000px) {
  .box-reset {
    margin-top: 20px;
    width: 300px;
  }
}
</style>