<template>
  <!-- <Login v-if="!isLoged" /> -->

  <component v-if="!isLoged" :is="getPage()"> </component>

  <Loader :cargando="this.$store.state.loader"></Loader>
  <Navbar v-if="isLoged && this.getVisualWidth > 1000" />
  <NavbarMovil v-if="isLoged && this.getVisualWidth < 1000" />
  <!-- <router-view /> -->
</template>

<script>
import Login from "./modules/auth/view/Login.vue";
import Loader from "./components/Loader.vue";
import { mapGetters } from "vuex";
import Navbar from "./components/Navbar.vue";
import NavbarMovil from "./components/NavbarMovil.vue";
import { onMounted, provide, reactive, ref } from "vue-demi";
// import Swal from "sweetalert2";
import ResetPassword from "./modules/auth/view/ResetPassword.vue";
import useAuth from "./modules/auth/composable/useAuth";

export default {
  components: {
    Login,
    Loader,
    Navbar,
    NavbarMovil,
    ResetPassword,
  },
  computed: {
    ...mapGetters(["getUser", "getVisualWidth"]),
  },
  created() {
    // setInterval(async () => {
    //   this.verificarSesion();
    // }, 60000);
  },
  methods: {
    async verificarSesion() {
      let tk = this.$store.getters.getToken
        ? this.$store.getters.getToken.access_token
        : null;
      const validationTk = await this.$store.dispatch("isValidToken");
      if (!validationTk || tk == null) {
        this.$store.dispatch("clearAndRedirect");
        this.$router.push("/");
      }
    },
  },
  async mounted() {
    window.addEventListener("resize", (e) => {
      this.$store.commit("setVisualWidth", e.target.visualViewport.width);
      this.$store.commit("setVisualHeight", e.target.visualViewport.height);
    });
    this.$store.commit("hideLoader");
  },
  setup() {
    const btn = ref(false);
    const imageUrl = ref(null);
    const imageUrlp = ref(null);
    const imgPerfil = ref(process.env.VUE_APP_URL_IMG);
    const { isLoged, checkSession } = useAuth();
    const objUser = reactive({
      id: null,
      name: "",
      email: "",
      telefono: "",
      role_id: 3,
      password: "",
      password_confirmation: "",
      foto: "",
      status: true,
      tmp: "",
      deleted: false,
    });
    const objLogin = reactive({
      email: "",
      password: "",
      password_confirmation: "",
      resetToken: "",
    });

    const modalGaleria = ref(false);

    const getPage = () => {
      return localStorage.getItem("page");
    };

    setInterval(() => {
      checkSession();
    }, 60000);

    onMounted(() => {
      checkSession();
    });

    let url_string = window.location.href;
    let url = new URL(url_string);
    let tokenReset = url.searchParams.get("token");
    let email = url.searchParams.get("email");

    if (tokenReset) {
      objLogin.resetToken = tokenReset;
      objLogin.email = email;
      localStorage.setItem("page", "ResetPassword");
    } else {
      localStorage.setItem("page", "login");
    }

    provide("btn", btn);
    provide("objUser", objUser);
    provide("objLogin", objLogin);
    provide("imageUrl", imageUrl);
    provide("imageUrlp", imageUrlp);
    provide("imgPerfil", imgPerfil);
    provide("modalGaleria", modalGaleria);

    return { getPage, isLoged };
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
* {
  font-family: "Poppins", sans-serif !important;
}
body {
  background: #232323 !important;
}

.txt-red {
  /* color: red; */
  color: #ffff00;
}

.myButtonAdd {
  box-shadow: inset 0px 1px 0px 0px #a4e271;
  background: linear-gradient(to bottom, #89c403 5%, #77a809 100%);
  background-color: #89c403;
  border-radius: 6px;
  border: 1px solid #74b807;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #528009;
}
.myButtonAdd:hover {
  background: linear-gradient(to bottom, #77a809 5%, #89c403 100%);
  background-color: #77a809;
}
.myButtonAdd:active {
  position: relative;
  top: 1px;
}

.myButtonAceptar {
  box-shadow: inset 0px 1px 0px 0px #97c4fe;
  background: linear-gradient(to bottom, #3d94f6 5%, #1e62d0 100%);
  background-color: #3d94f6;
  /* border-radius: 6px; */
  border: 1px solid #337fed;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #1570cd;
}
.myButtonAceptar:hover {
  background: linear-gradient(to bottom, #1e62d0 5%, #3d94f6 100%);
  background-color: #1e62d0;
}
.myButtonAceptar:active {
  position: relative;
  top: 1px;
}

.btnEliminarTable {
  box-shadow: inset 0px 1px 0px 0px #f29c93;
  background: linear-gradient(to bottom, #fe1a00 5%, #ce0100 100%);
  background-color: #fe1a00;
  /* border-radius: 6px; */
  border: 1px solid #d83526;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 4px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #b23e35;
  width: 30px;
}
.btnEliminarTable:hover {
  background: linear-gradient(to bottom, #ce0100 5%, #fe1a00 100%);
  background-color: #ce0100;
}
.btnEliminarTable:active {
  position: relative;
  top: 1px;
}

.btnEliminarTable1 {
  box-shadow: inset 0px 1px 0px 0px #f29c93;
  background: linear-gradient(to bottom, #fe1a00 5%, #ce0100 100%);
  background-color: #fe1a00;
  /* border-radius: 6px; */
  border: 1px solid #d83526;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 4px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #b23e35;
}
.btnEliminarTable1:hover {
  background: linear-gradient(to bottom, #ce0100 5%, #fe1a00 100%);
  background-color: #ce0100;
}
.btnEliminarTable1:active {
  position: relative;
  top: 1px;
}

.myButtonEliminar {
  box-shadow: inset 0px 1px 0px 0px #f5978e;
  background: linear-gradient(to bottom, #f24537 5%, #c62d1f 100%);
  background-color: #f24537;
  /* border-radius: 6px; */
  border: 1px solid #d02718;
  display: inline-block;                                                                                                                                                                                              
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #810e05;
}
.myButtonEliminar:hover {
  background: linear-gradient(to bottom, #c62d1f 5%, #f24537 100%);
  background-color: #c62d1f;
}
.myButtonEliminar:active {
  position: relative;
  top: 1px;
}

.table > :not(:first-child) {
  border-top: 1px solid currentColor;
}

.myButtonEdit {
	box-shadow:inset 0px 1px 0px 0px #a4e271;
	background:linear-gradient(to bottom, #89c403 5%, #77a809 100%);
	background-color:#89c403;
  border-radius: 6px;
	border:1px solid #74b807;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 4px;
  text-decoration: none;
	text-shadow:0px 1px 0px #528009;
}
.myButtonEdit:hover {
	background:linear-gradient(to bottom, #77a809 5%, #89c403 100%);
	background-color:#77a809;
}
.myButtonEdit:active {
  position: relative;
  top: 1px;
}





</style>
