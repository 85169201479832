import state from './state'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'
import createPersistedState from "vuex-persistedstate";

export default {
  plugins: [createPersistedState()],
  namespaced: true,
  getters,
  mutations,
  actions,
  state
}
