<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light mb-2">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"
        ><img src="/img/img2x.png" height="80" alt=""
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li v-for="m of menu" v-bind:key="m.menu" class="nav-item">
            <router-link
              :to="m.to"
              class="nav-link"
              @click="ocultarMenu"
              v-if="m.rol == user.role.nombre || m.rol == ''"
            >
              <p>{{ m.menu }}</p>
            </router-link>
          </li>

          <li class="nav-item">
            <a to="/perfil" class="nav-link" href="#" @click="logOut"
              >Desconectar</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<script>
import { useStore } from "vuex";
import $ from "jquery";
import Swal from "sweetalert2";
import menu from "../store/data/menu.json";
import useAuth from "../modules/auth/composable/useAuth"
export default {
  setup() {
    const store = useStore();
    const ocultarMenu = () => {
      $("#navbarSupportedContent").removeClass("show");
    };

    const {user ,logout} = useAuth();    

    const logOut = () => {
      Swal.fire({
        title: "¿Seguro que desea salir?",
        showCancelButton: true,
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.isConfirmed) {
          store.commit("showLoader");
          // await store.dispatch("logOut");
          await logout();

          setTimeout(() => {
            store.commit("hideLoader");
          }, 2000);
        }
      });
    };
    return { logOut, ocultarMenu, menu, user };
  },
};
</script>

<style>
</style>