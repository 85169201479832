import baseApi from "@/api/baseApi";

export const createPromociones = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/createPromociones", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};

export const editPromociones = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/editPromociones", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};
export const deletePromociones = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/deletePromociones", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};

export const getPromociones = async ({ commit }, obj) => {
  const { data } = await baseApi.get("/api/v1/getPromociones", {
    params: {
      perPage: obj.perPage,
    },
  });
  commit("setPromociones", data.data);
  return data;
};
