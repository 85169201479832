export function getMensajes(state) {
  return state.mensajes;
}
export function getMensajesTipo(state) {
  return state.mensajesTipo;
}






