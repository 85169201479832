import baseApi from "@/api/baseApi";

export const createImagen = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/s3/createImagen", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};

export const getS3Carpetas = async ({ commit }) => {
  const { data } = await baseApi.get("/api/v1/s3/getImagen?carpeta=");

  let carpetas = [];
  data.data.forEach((element) => {
    carpetas.push(element.Key.split("/")[1]);
  });

  let data1 = carpetas;
  const result = data1.reduce((acc, item) => {
    if (!acc.includes(item)) {
      acc.push(item);
    }
    return acc;
  }, []);

  commit("setS3carpetas", result);

  return data;
};
export const getImagen = async ({ commit }, obj) => {
  const { data } = await baseApi.get("/api/v1/s3/getImagen", {
    params: {
      carpeta: obj.carpeta,
    },
  });
  let arr = [];
  data.data.forEach((element) => {
    let video = /(.mp4)$/i;
    let tipo = "img";
    if (video.exec(element.Key)) {
      tipo = "video";
    }
    arr.push({ id: element.Key, tipo });
  });
  commit("setS3galeria", arr);

  return data;
};

export const deleteImagen = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/s3/deleteImagen", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};
