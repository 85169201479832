export function setAyudas(state, ayudas) {  
  state.ayudas = ayudas;
}
export function setPaginas(state, paginas) {
  state.paginas = paginas;
}




