import baseApi from "@/api/baseApi";

export const getUser = async ({ commit }) => {
  try {
    const { data } = await baseApi.get("/api/v1/getUser");
    if (data.code == 200) {
      commit("setUsuarios", data.data);
    }
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};
export const editUser = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/editUser", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};
export const createUser = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/auth/v1/createUser", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};
export const changePassword = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/changePassword", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    localStorage.removeItem("sp_tk");
    window.location.reload();
  }
};
