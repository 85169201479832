import baseApi from "@/api/baseApi";

export const createEvento = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/createEvento", obj);

    console.log("RES:::",data)
    return data;
  } catch (error) {
    console.log("Error", error);
    // localStorage.removeItem("sp_tk");
    // window.location.reload();
  }
};
export const createCompetidores = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/createCompetidores", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    // localStorage.removeItem("sp_tk");
    // window.location.reload();
  }
};
export const getPronostico = async ({ commit }) => {
  const { data } = await baseApi.get("/api/v1/getPronostico");
  commit("setPronostico", data.data);
  return data;
};

export const getCompetidores = async ({ commit },obj) => {
  const { data } = await baseApi.get("/api/v1/getCompetidores",{
    params:{
      deporte_id:obj.deporte_id,
      nombre:obj.nombre,
    }
  });
  commit("setCompetidores", data.data);
  return data;
};

export const deletePronostico = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/deletePronostico", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    // localStorage.removeItem("sp_tk");
    // window.location.reload();
  }
};
export const deleteCompetidores = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/deleteCompetidores", obj);    
    return data;
  } catch (error) {
    console.log("Error", error);
    // localStorage.removeItem("sp_tk");
    // window.location.reload();
  }
};
export const editPronostico = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/editPronostico", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    // localStorage.removeItem("sp_tk");
    // window.location.reload();
  }
};
export const editEvento = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/editEvento", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    // localStorage.removeItem("sp_tk");
    // window.location.reload();
  }
};
export const editCompetidores = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/editCompetidores", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    // localStorage.removeItem("sp_tk");
    // window.location.reload();
  }
};
export const getEvento = async ({ commit }, obj) => {
  try {
    const { data } = await baseApi.get("/api/v1/getEvento", {
      params: {
        pronostico_id: obj.pronostico_id,
      },
    });
    commit("setPronostico", data.data);
    commit("setEvento", data.data[0].eventos);
    commit("setCompetidores", data.data[0].competidores);
    return data;
  } catch (error) {
    console.log("Error", error);
    // localStorage.removeItem("sp_tk");
    // window.location.reload();
  }
};
export const getDeporte = async ({ commit }) => {
  try {
    const { data } = await baseApi.get("/api/v1/getDeporte");

    commit("setDeporte", data.data);
 
    return data;
  } catch (error) {
    console.log("Error", error);
    // localStorage.removeItem("sp_tk");
    // window.location.reload();
  }
};
export const findEvento = async ({ commit }, id) => {
  try {
    const { data } = await baseApi.get("/api/v1/findEvento/" + id);    
    commit("setEvento", data.data);
    return data;
  } catch (error) {
    console.log("Error", error);
    // localStorage.removeItem("sp_tk");
    // window.location.reload();
  }
};
export const addEvento = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/addEvento", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    // localStorage.removeItem("sp_tk");
    // window.location.reload();
  }
};
export const deleteEvento = async (contex, obj) => {
  try {
    const { data } = await baseApi.post("/api/v1/deleteEvento", obj);
    return data;
  } catch (error) {
    console.log("Error", error);
    // localStorage.removeItem("sp_tk");
    // window.location.reload();
  }
};
