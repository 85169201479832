export function setDeportes(state, payload) {
  state.deportes = payload;
}








