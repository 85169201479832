<template>
  <div class="sidebar-mini layout-fixed layout-navbar-fixed" style="z-index:-1">
    <div class="wrapper">
      <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <a href="/" class="brand-link">
          <img
            src="/img/img2x.png"
            alt="SYSTEM4SELLA Logo"
            class="brand-image img-circle elevation-3"
            style="opacity: 0.8"
          />
          <span class="brand-text font-weight-light">SYSTEM4SELLA</span>
        </a>
        <div class="sidebar">
          <div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="image">
              <img
                v-if="user.foto"
                :src="imgPerfil + user.foto"
                class="img-circle elevation-2"
                alt="User Image"
              />
              <img
                v-else
                src="img/no-img.jpg"
                class="img-circle elevation-2"
                alt="User Image"
              />
            </div>
            <div class="info">
              <router-link to="/perfil" class="d-block">{{
                user.email
              }}</router-link>
            </div>
          </div>
          <!-- Sidebar Menu -->
          <nav class="mt-2">
            <ul
              class="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
            >
              <li v-for="m of menu" v-bind:key="m.menu" class="nav-item">
                <router-link :to="m.to" class="nav-link" v-if="m.rol == user.role.nombre || m.rol == ''">
                  <p>{{ m.menu }}</p>
                </router-link>
              </li>           
              <li class="nav-item">
                <a
                  v-on:click="logOut()"
                  class="nav-link"
                  style="cursor: pointer"
                >
                  <p>Desconectar</p>
                </a>
              </li>
            </ul>
          </nav>
          <!-- /.sidebar-menu -->
        </div>
        <!-- /.sidebar -->
      </aside>

      <div class="content-wrapper px-4">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue-demi";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import menu from "../store/data/menu.json";
import useAuth from '../modules/auth/composable/useAuth';

export default {
  setup() {
    const store = useStore();   
    const imgPerfil = inject("imgPerfil");    
    const {user,logout} = useAuth();
    const logOut = () => {
      Swal.fire({
        title: "¿Seguro que desea salir?",
        showCancelButton: true,
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.isConfirmed) {
          store.commit("showLoader");
          // await store.dispatch("logOut");
          await logout();

          setTimeout(() => {
            store.commit("hideLoader");
          }, 2000);
        }
      });
    };  

    return {
      user,
      logOut,
      imgPerfil,
      menu
      
    };
  },
};
</script>

<style scoped>
a {
  color: #fff;
  font-weight: bold;
}
.menu-fijo {
  position: fixed;
  /* border-right: solid 1px #212529; */
}
a.router-link-active {
  background: #0068ff;
  color: #fff;
}
.content-wrapper {
  background: #232323;
}
.layout-fixed .main-sidebar {
  bottom: 0;
  float: none;
  left: 0;
  position: fixed;
  top: 0;
}
</style>