export function getPronostico(state) {
  return state.pronostico;
}
export function getEvento(state) {
  return state.evento;
}
export function getCompetidores(state) {
  return state.competidores;
}
export function getDeporte(state) {
  return state.deporte;
}









