import authApi from "@/api/authApi";
import jwt from "jsonwebtoken";

export const signInUser = async ({ commit }, obj) => {
  const { data } = await authApi.post("/api/auth/v1/login", obj);

  if (data.code == 200) {
    const payload = data.data.token.split(".")[1];
    commit("setUser", JSON.parse(atob(payload)));
    commit("setToken", data.data.token);
    localStorage.setItem("token", data.data.token);

    let tokenSerealized = jwt.sign(
      {
        tk: data.data.token,
        creation: new Date(),
        online: true,
      },
      process.env.VUE_APP_API_SECRET,
      { expiresIn: process.env.VUE_APP_SESSION_EXPIRATION }
    );
    if (localStorage && !localStorage.getItem("sp_tk")) {
      localStorage.setItem("sp_tk", tokenSerealized);
    }
  }
  return data;
};

export const checkSession = async ({ commit }) => {  
  if (localStorage && localStorage.getItem("sp_tk")) {
    const tkSerial = localStorage.getItem("sp_tk");

    jwt.verify(
      tkSerial,
      process.env.VUE_APP_API_SECRET,
      async function (err, decoded) {
        if (!err && decoded.tk) {
          commit("setToken", decoded.tk);
        } else {
          localStorage.removeItem("sp_tk");          
          commit("setUser", null);
          commit("setToken", null);
        }
      }
    );
  }else{
    localStorage.removeItem("sp_tk");    
    commit("setUser", null);
    commit("setToken", null);
  }
};

export const logout = async ({ commit }) => {  
  try {
    const { data } = await authApi.post("/api/auth/v1/logout");
    localStorage.removeItem("sp_tk");
    commit("setUser", null);
    commit("setToken", null);
    return data;
  } catch (error) {
    localStorage.removeItem("sp_tk");
    commit("setUser", null);
    commit("setToken", null);
  }
};

export const sendPasswordResetLinkAsync = async ({ commit }, obj) => {
  const { data } = await authApi.post("/api/v1/sendPasswordResetLink", obj);
  commit("setUser", null);
  commit("setToken", null);
  return data;
};

export const resetPasswordAsync = async ({ commit }, obj) => {
  const { data } = await authApi.post("/api/v1/resetPassword", obj);
  commit("setUser", null);
  commit("setToken", null);
  return data;
};
